@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.liveMatchCardList {
  padding-top: 28px;
  overflow: hidden;
  border-radius: 16px;
  border: 1px solid var(--border-light);
}

.teamImg {
  width: 24px;
  border-radius: 50%;
  overflow: hidden;
}

.liveMatchCard:not(:last-child){
  border-bottom: 1px solid var(--border-light);
}

.liveMatchCard {
  padding: 38px 16px 14px;
  margin-top: -28px;
  background: var(--light-mode-bg);
  z-index: 3;
  position: relative;

  &:first-child {
    padding-top: 24px;
    padding-bottom: 24px;

    .teamImg {
      width: 44px;
    }
  }

  &:nth-child(2) {
    z-index: 2;
  }

  &:last-child {
    z-index: 1;
  }

  p {
    margin: 0;
  }
}

.team {
  width: 160px;

  .name {
    font-size: 12px;

    b {
      margin: 0px 4px;
    }
  }
}

.info {
  width: calc(100% - 320px);
  text-transform: capitalize;
}

.score {
  margin-top: 4px;
}

.scoreCardLink {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 9999;
  background: transparent;
}

/***** Responsive CSS Start ******/

@media (min-width: 992px) and (max-width: 1199px) {
  .liveMatchCardList {
    padding-top: 22px;
  }

  .teamImg {
    width: 24px;
  }

  .liveMatchCard {
    padding: 28px 12px 12px;
    margin-top: -22px;

    &:first-child {
      padding-top: 20px;
      padding-bottom: 20px;

      .teamImg {
        width: 44px;
      }
    }

    p {
      margin: 0;
    }
  }

  .team {
    width: 90px;
  }

  .info {
    width: calc(100% - 180px);
  }
}

@media (max-width: 575px) {
  .teamImg {
    width: 22px;
  }

  .liveMatchCard {
    padding: 30px 6px 10px;
    margin-top: -22px;
    align-items: baseline !important;
    // border-radius: 16px;

    &:first-child {
      padding-top: 16px;
      padding-bottom: 16px;

      .teamImg {
        width: 30px;
      }
    }

    p {
      margin: 0;
    }
  }

  .team {
    width: 104px;
  }

  .info {
    width: calc(100% - 208px);
  }
}